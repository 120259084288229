import React from "react";

import Divider from "../Divider";

import styles from "./index.module.css";

const Footer = () => (
  <>
    <Divider />
    <footer className={styles.el}>
      <div className={styles.container}>
        <div className={styles.sections}>
          <div className={styles.address}>
            <span>© GFAA {new Date().getFullYear()}</span>
            <span>505 Mercer Road</span>
            <span>Princeton, NJ 08540</span>
          </div>
          <a
            className={styles.link}
            href="https://www.instagram.com/glenfriesarchitects/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Follow us on Instagram
          </a>
        </div>
      </div>
    </footer>
  </>
);

export default Footer;
