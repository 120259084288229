import React from "react";

import HeaderLogo from "./HeaderLogo";
import HeaderMenu from "./HeaderMenu";
import HeaderMobileMenu from "./HeaderMobileMenu";

import styles from "./index.module.css";

const Header = props => (
  <div className={styles.el}>
    <div className={styles.container}>
      <HeaderLogo />
      <HeaderMenu location={props.location} />
      <HeaderMobileMenu />
    </div>
  </div>
);

export default Header;
