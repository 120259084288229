import { globalHistory } from "@reach/router";
import { Link } from "gatsby";
import React from "react";
import styles from "./HeaderMenu.module.css";

const HeaderMenu = props => (
  <div className={styles.el}>
    <div className={styles.contacts}>
      <a className={styles.contact} href="tel:+16099248700">
        (609)-924-8700
      </a>
    </div>
    <div
      className={`${styles.links} ${!globalHistory.location.pathname.includes(
        "portfolio"
      ) && styles.linksHome}`}
    >
      {!globalHistory.location.pathname.includes("portfolio") ? (
        <>
          <Link
            activeClassName={styles.active}
            className={styles.link}
            to="/portfolio/houses/"
          >
            Residential
          </Link>
          <Link
            activeClassName={styles.active}
            className={styles.link}
            to="/portfolio/commercial/"
          >
            Commercial
          </Link>
          <Link
            activeClassName={styles.active}
            className={styles.link}
            to="/portfolio/exteriors/"
          >
            Gardens &amp; Pools
          </Link>
        </>
      ) : (
        <Link
          activeClassName={styles.active}
          className={`${styles.link} ${globalHistory.location.pathname.includes(
            "/portfolio"
          ) && styles.active}`}
          to="/portfolio/"
        >
          Portfolio
        </Link>
      )}
      <Link
        activeClassName={styles.active}
        className={styles.link}
        to="/company/"
      >
        Company
      </Link>
      <Link
        activeClassName={styles.active}
        className={styles.link}
        to="/contact/"
      >
        Contact
      </Link>
    </div>
  </div>
);

export default HeaderMenu;
