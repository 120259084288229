import { Link } from "gatsby";
import React from "react";

import styles from "./HeaderLogo.module.css";

const HeaderLogo = () => (
  <Link className={styles.el} to="/">Glen Fries Architects Associates</Link>
);

export default HeaderLogo;
