import { Link } from "gatsby";
import { graphql, StaticQuery } from "gatsby";
import React from "react";
import { globalHistory } from "@reach/router";

import styles from "./HeaderMobileMenu.module.css";

class HeaderMobileMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menu: false,
    };
  }

  openMenu = () => {
    this.setState(
      {
        menu: true,
      },
      () => document.body.classList.add("lockedMobile")
    );
  };

  closeMenu = () => {
    this.setState(
      {
        menu: false,
      },
      () => document.body.classList.remove("lockedMobile")
    );
  };

  render() {
    return (
      <div className={`${styles.el}`}>
        <button
          className={`${styles.toggle} ${styles.openButton}`}
          onClick={() => this.openMenu()}
        >
          <div>
            <span>M</span>
            <span>e</span>
            <span>n</span>
            <span>u</span>
          </div>
        </button>
        <div className={`${styles.menu} ${this.state.menu && styles.active}`}>
          <Link activeClassName={styles.active} className={styles.link} to="/">
            Home
          </Link>
          <Link
            activeClassName={styles.active}
            className={`${
              styles.link
            } ${globalHistory.location.pathname.includes("/portfolio/") &&
              styles.active}`}
            onClick={() => this.closeMenu()}
            to="/portfolio/"
          >
            Portfolio
          </Link>
          <Link
            activeClassName={styles.active}
            className={styles.link}
            onClick={() => this.closeMenu()}
            to="/company/"
          >
            Company
          </Link>
          <Link
            activeClassName={styles.active}
            className={styles.link}
            onClick={() => this.closeMenu()}
            to="/contact/"
          >
            Contact
          </Link>
          <a
            className={styles.link}
            href="https://www.instagram.com/glenfriesarchitects/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Instagram
          </a>
          <a className={styles.link} href="tel:+16099248700">
            (609)-924-8700
          </a>
          <button
            className={`${styles.toggle} ${styles.closeButton}`}
            onClick={() => this.closeMenu()}
          >
            Close
          </button>
        </div>
      </div>
    );
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulPortfolioSection {
          edges {
            node {
              slug
              title
            }
          }
        }
      }
    `}
    render={data => (
      <HeaderMobileMenu
        portfolioSections={data.allContentfulPortfolioSection}
      />
    )}
  />
);
